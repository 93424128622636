<template>
  <div class="main">
    <div class="head">
      <div class="search">
        <span>搜索</span>
        <el-input placeholder="人员信息" v-model="search" @change="searchuser"></el-input>
      </div>
      <div class="control">
        <el-tooltip effect="light" content="添加管理员">
          <el-button icon="plus" type="primary" @click="adddialog=!adddialog"></el-button>
        </el-tooltip>
        <el-tooltip effect="light" content="刷新">
          <el-button icon="RefreshRight" type="success" @click="router.go(0)"></el-button>
        </el-tooltip>
      </div>
    </div>
    <div class="contain">
      <el-table :data="user" border :row-style="row_style">
        <el-table-column label="ID" prop="id" width="80"></el-table-column>
        <el-table-column label="类型" prop="type" width="80"></el-table-column>
        <el-table-column label="名字" prop="name" width="120"></el-table-column>
        <el-table-column label="账号" prop="account"></el-table-column>
        <el-table-column label="头像" #default="scoped">
          <img id="tx" :src="scoped.row.image" @click="show.img=scoped.row.image;show.is=true">
        </el-table-column>
        <el-table-column label="性别" width="60" prop="sex"></el-table-column>
        <el-table-column label="电话" prop="phone"></el-table-column>
        <el-table-column label="管理" #default="scoped">
          <el-tooltip content="修改信息" effect="light" placement="top">
            <el-button icon="edit" type="primary" size="small" @click="showfn(scoped.row)"></el-button>
          </el-tooltip>
          <el-tooltip content="删除" effect="light" placement="top">
            <el-button icon="Delete" type="danger" @click="deleteuser(scoped.row.id)" size="small"></el-button>
          </el-tooltip>
        </el-table-column>
      </el-table>
    </div>
  </div>
  <el-dialog title="添加管理员" center align-center v-model="adddialog">
    <el-form :model="addform" label-width="80">
      <el-form-item label="账号权限">
        <el-select v-model="addform.type">
          <el-option v-for="(item,index) in usertype" :key="index" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="账号">
        <el-input v-model="addform.account"></el-input>
      </el-form-item>
      <el-form-item label="名字">
        <el-input v-model="addform.name" placeholder="请输入名字"></el-input>
      </el-form-item>
      <el-form-item label="性别">
        <el-radio-group v-model="addform.sex">
          <el-radio label="男"></el-radio>
          <el-radio label="女"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="电话">
        <el-input v-model="addform.phone" placeholder="请输入电话"></el-input>
      </el-form-item>
      <el-form-item label="账号密码">
        <el-input v-model="addform.password"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" @click="adduser">添加</el-button>
      <el-button type="danger" @click="adddialog=!adddialog">取消</el-button>
    </template>
  </el-dialog>
  <el-dialog v-model="show.is" align-center center :show-close="false" style="background: none;padding: 0">
    <img id="txshow" :src="show.img">
  </el-dialog>
  <el-dialog title="修改信息" align-center v-model="showdialog">
    <el-form :model="showform" label-width="80">
      <el-form-item label="账号权限">
        <el-select v-model="showform.type">
          <el-option v-for="(item,index) in usertype" :key="index" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="账号">
        <el-text>{{showform.account}}</el-text>
      </el-form-item>
      <el-form-item label="名字">
        <el-text>{{showform.name}}</el-text>
      </el-form-item>
      <el-form-item label="头像">
        <img id="tx-0" :src="showform.image">
      </el-form-item>
      <el-form-item label="性别">
        <el-text>{{showform.sex}}</el-text>
      </el-form-item>
      <el-form-item label="电话">
        <el-input v-model="showform.phone" placeholder="请输入电话"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" @click="modifyuser">修改</el-button>
      <el-button type="danger" @click="showdialog=!showdialog">取消</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue";
import {api} from "@/api/api";
import router from "@/router";
import {ElMessage} from "element-plus";

const adddialog=ref(false)
const addform=reactive({
  account:'admin',
  name:'',
  sex:'男',
  phone:'',
  password:'123456',
  type:'admin'
})
const usertype=reactive([
  {label:'admin',value:'admin'}
])
const user=reactive([])
const search=ref('')
const show=reactive({
  is:false,
  img:''
})
const showform=reactive({
  id:'',
  account:'',
  name:'',
  sex:'',
  phone:'',
  type:'',
  image:''
})
const showdialog=ref(false)

const row_style=({rowIndex})=>{
  if(rowIndex%4==0){
    return {background: "rgba(64,158,255,0.25)"}
  }else if (rowIndex%4==1){
    return {background: "rgba(103,194,58,0.25)"}
  }else if (rowIndex%4==2){
    return {background: "rgba(115,101,255,0.25)"}
  }else {
    return {background: "rgba(230,162,60,0.2)"}
  }
}

function showfn(x){
  showdialog.value=!showdialog.value
  showform.id=x.id
  showform.account=x.account
  showform.name=x.name
  showform.sex=x.sex
  showform.phone=x.phone
  showform.type=x.type
  showform.image=x.image
}
function searchuser(){
  api({
    method:'post',
    url:'user/search',
    data:{search:search.value},
    success:(res)=>{
      if(res.state==200){
        user.splice(0)
        res.data.forEach((i)=>{
          user.push(i)
        })
      }else {
        ElMessage.error("查询失败！")
      }
    }
  })
}
function getuser(){
  api({
    method:'get',
    url:'user/get',
    data:null,
    success:(res)=>{
      if(res.state==200){
        user.splice(0)
        res.data.forEach((i)=>{
          user.push(i)
        })
      }else {
        ElMessage.error("获取人事信息失败！")
      }
    }
  })
}
function adduser(){
  if(addform.name==''){
    ElMessage.error("请完善信息，填写姓名！")
  }else {
    api({
      method:'post',
      url:'user/add',
      data:addform,
      success:(res)=>{
        if(res.state==200){
          ElMessage.success(res.msg)
          addform.name=''
          addform.phone=''
          getuser()
        }else {
          ElMessage.error(res.msg)
        }
      }
    })
  }
}
onMounted(()=>{
  getuser()
})
function deleteuser(x){
  api({
    method:'post',
    url:'user/delete',
    data:{id:x},
    success:(res)=>{
      if(res.state==200){
        ElMessage.success(res.msg)
        getuser()
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}
function modifyuser(){
  api({
    method:'post',
    url:'user/update',
    data:showform,
    success:(res)=>{
      if(res.state==200){
        ElMessage.success(res.msg)
        getuser()
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}
</script>

<style scoped>
.main{
  width: 100%;
  height: 100%;
}
.main .head{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 2rem;
  padding-bottom: 0.9rem;
}
.main .head .search{
  width: 30%;
  margin-left: 2.5%;
  display: flex;
  align-items: center;
}
.main .head .search .el-input{
  width: 70%;
}
.main .head .search span{
  display: block;
  width: 15%;
}
.main .head .control{
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: row-reverse;
}
.main .head .control .el-button:first-child{
  margin-right: 10%;
  margin-left: 4%;
}
.main .contain{
  width: 100%;
  height: 79.2%;
}
#tx{
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 0.2rem;
  box-shadow: 0 0 0.3rem gray;
  margin-top: 0.2rem;
}
#txshow{
  height: 94vh;
  position: absolute;
  top: -41vh;
  left: -2rem;
}
#tx-0{
  width: 6rem;
  height: 6rem;
  margin-left: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.4rem gray;
}
</style>