<template>
  <div class="main">
    <div class="head">
      <el-button type="primary" @click="clear" :disabled="exhibit.length<1">清空</el-button>
    </div>
    <div class="contain">
      <div class="item" v-for="(item,index) in exhibit" :key="index">
        <img :src="item.goods.image" @click="showdialog=!showdialog;showdata.image=item.goods.image;showdata.id=item.id">
        <div class="box">
          <p class="name">{{item.goods.name}}</p>
          <div class="bottom">
            <el-button type="danger"  size="small" @click="deleteexhibit(item.id)">删除</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue";
import {api} from "@/api/api";
import {ElMessage} from "element-plus";
import {apis} from "@/api/apis";

const exhibit=reactive([])
const showdialog=ref(false)
const showdata=reactive({
  id:'',
  image:''
})
function clear(){
  apis({
    method:'post',
    url:'system/clearshow',
    data:JSON.stringify(exhibit),
    success:(res)=>{
      if (res.state==200){
        exhibit.splice(0)
        ElMessage.success(res.msg)
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}
function deleteexhibit(x){
  api({
    method:'post',
    url:'system/deleteshow',
    data:{id:x},
    success:(res)=>{
      if (res.state==200){
        ElMessage.success(res.msg)
        for (let i = 0; i < exhibit.length; i++) {
          if (exhibit[i].id==x){
            exhibit.splice(i,1)
          }
        }
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}
function getexhibit(){
  api({
    method:'get',
    url:'system/getshow',
    success:(res)=>{
      if (res.state==200){
        exhibit.splice(0)
        res.data.forEach(i=>{
          exhibit.push(i)
        })
      }else {
        ElMessage.error("获取展品失败！")
      }
    }
  })
}
onMounted(()=>{
  getexhibit()
})
</script>

<style scoped>
.main{
  width: 100%;
}
.main .head{
  width: 100%;
  height: 3rem;
  display: flex;
}
.main .head .el-button{
  margin-left: 85%;
  margin-top: 0.8rem;
}
.main .contain{
  width: 100%;
  height: 89.55%;
  padding-bottom: 1rem;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
}
.main .contain .item{
  width: 17.6%;
  height: 14rem;
  margin-top: 1rem;
  margin-left: 2%;
  box-shadow: 0 0 0.2rem gray;
  border-radius: 0.5rem;
}
.main .contain .item:hover{
  box-shadow: 0 0 0.4rem orangered;
}
.main .contain .item img{
  width: 100%;
  height: 10rem;
  border-radius: 0.5rem 0.5rem 0 0;
}
.main .contain .item .box{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.main .contain .item .box .name{
  margin-left: 1rem;
  margin-top: 0.5rem;
}
.main .contain .item .box .price span{
  color: orangered;
  font-size: 1rem;
}
.main .contain .item .box .bottom{
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.main .contain .item .box .bottom .el-button{
  margin-right: 1rem;
}
.txbox{
  width: 100%;
  display: flex;
}
.txbox #show{
  width: 50%;
  box-shadow: 0 0 0.4rem gray;
  border-radius: 0.5rem;
  margin-right: 2rem;
}
</style>