<template>
<div class="main">
  <el-form class="forms">
    <el-form-item label="小程序公告" label-width="150">
      <span class="sp_0" @click="select_two(1)">{{info.notice}}</span>
    </el-form-item>
    <el-form-item label="小程序展品标题" label-width="150">
      <span class="sp_1" @click="select_two(2)">{{info.title}}</span>
    </el-form-item>
  </el-form>
</div>
  <el-dialog v-model="dialogshow" center align-center>
    <el-form>
      <el-form-item>
        <el-input type="textarea" :autosize="{minRows:4}" v-model="dataform.data"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="qr">修改</el-button>
        <el-button type="danger" @click="dialogshow=!dialogshow">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue";
import {api} from "@/api/api";
import {ElMessage} from "element-plus";

const info=reactive({
  notice:'',
  title:''
})
const dataform=reactive({
  type:1,
  data:''
})
const dialogshow=ref(false)
function getnotice(){
  api({
    method:'get',
    url:'system/getnotice',
    success:(res)=>{
      if (res.state==200){
        info.notice=res.data
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}
function gettitle(){
  api({
    method:'get',
    url:'system/gettitle',
    success:(res)=>{
      if (res.state==200){
        info.title=res.data
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}
function modifynotice(x){
  api({
    method:'post',
    url:'system/updatenotice',
    data:{data:x},
    success:(res)=>{
      if (res.state==200){
        ElMessage.success(res.msg)
        info.notice=res.data
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}
function modifytitle(x){
  api({
    method:'post',
    url:'system/updatetitle',
    data:{data:x},
    success:(res)=>{
      if (res.state==200){
        ElMessage.success(res.msg)
        info.title=res.data
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}
onMounted(()=>{
  getnotice()
  gettitle()
})
function select_two(x){
  if (x==1){
    dataform.type=1
    dataform.data=info.notice
  }else {
    dataform.type=2
    dataform.data=info.title
  }
  dialogshow.value=true
}
function qr(){
  if (dataform.type==1){
    modifynotice(dataform.data)
  }else {
    modifytitle(dataform.data)
  }
}
</script>

<style scoped>
.main{
  width: 100%;
  height: 100%;
  display: flex;
}
.main .forms{
  margin-left: 20%;
  margin-top: 20%;
}
.main .forms .sp_0{
  color: #409eff;
  max-width: 28vw;
  margin-left: 1rem;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.2rem deepskyblue;
  transition: 0.5s;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  overflow: hidden;
}
.main .forms .sp_0:hover{
  cursor: pointer;
  color: red;
  transform: scale(1.1);
  box-shadow: 0 0 0.2rem #409eff;
}
.main .forms .sp_1{
  color: orangered;
  max-width: 28vw;
  margin-left: 1rem;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.2rem orange;
  transition: 0.5s;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  overflow: hidden;
}
.main .forms .sp_1:hover{
  color: deepskyblue;
  cursor: pointer;
  transform: scale(1.1);
  box-shadow: 0 0 0.2rem orangered;
}
</style>