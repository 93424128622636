import $ from 'jquery'
import {ElMessage} from "element-plus";


const host="ligeio.xyz"
const host2="https://"+host+":8080/"

export const api=({method="get",url,data=null,success})=>{
    $.ajax({
        type:method,
        url:host2+url,
        data:data,
        headers:{"Authorization":"Bear "+localStorage.getItem("token")},
        success:success,
        error:(res)=>{
            console.log(res)
            ElMessage.error("请求失败！")
        }
    })
}