import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Elementplus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import '@/assets/icon/iconfont.css'

const app=createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(store).use(router).use(Elementplus).mount('#app')
