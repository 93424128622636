<template>
<div class="main">
  <div class="head">
    <el-button type="primary" @click="clear" :disabled="prize.length<1">清空</el-button>
  </div>
  <div class="contain">
    <div class="item" v-for="(item,index) in prize" :key="index">
      <img :src="item.goods.image">
      <div class="box">
        <p class="name">{{item.goods.name}}</p>
        <p class="detail">{{item.goods.detail}}</p>
        <p class="price">价格：<span>{{item.goods.price}}</span></p>
        <div class="bottom">
          <el-button type="danger"  size="small" @click="deleteprize(item.id)">删除</el-button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
import {onMounted, reactive} from "vue";
import {api} from "@/api/api";
import {ElMessage} from "element-plus";
import {apis} from "@/api/apis";

const prize=reactive([])
function getprize(){
  api({
    method:'get',
    url:'system/getprize',
    success:(res)=>{
      if (res.state==200){
        prize.splice(0)
        res.data.forEach(i=>{
          prize.push(i)
        })
      }else {
        ElMessage.error("获取奖品信息失败！")
      }
    }
  })
}
onMounted(()=>{
  getprize()
})
function deleteprize(x){
  api({
    method:'post',
    url:'system/deleteprize',
    data:{id:x},
    success:(res)=>{
      if (res.state==200){
        ElMessage.success(res.msg)
        for (let i = 0; i < prize.length; i++) {
          if (prize[i].id==x){
            prize.splice(i,1)
            return
          }
        }
      }else {
        ElMessage.error("删除奖品失败！")
      }
    }
  })
}
function clear(){
  console.log(prize)
  apis({
    method:'post',
    url:'system/clearprize',
    data:JSON.stringify(prize),
    success:(res)=>{
      if (res.state==200){
        prize.splice(0)
        ElMessage.success(res.msg)
      }else {
        ElMessage.error("清空失败！")
      }
    }
  })
}
</script>

<style scoped>
.main{
  width: 100%;
}
.main .head{
  width: 100%;
  height: 3rem;
  display: flex;
}
.main .head .el-button{
  margin-left: 85%;
  margin-top: 0.8rem;
}
.main .contain{
  width: 100%;
  height: 89.55%;
  padding-bottom: 1rem;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
}
.main .contain .item{
  width: 17.6%;
  height: 16rem;
  margin-top: 1rem;
  margin-left: 2%;
  box-shadow: 0 0 0.2rem gray;
  border-radius: 0.5rem;
}
.main .contain .item:hover{
  box-shadow: 0 0 0.4rem orangered;
}
.main .contain .item img{
  width: 100%;
  height: 10rem;
  border-radius: 0.5rem 0.5rem 0 0;
}
.main .contain .item .box{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.main .contain .item .box .name{
  margin-left: 1rem;
  margin-top: 0.5rem;
}
.main .contain .item .box .detail{
  margin-left: 1rem;
  font-size: 0.7rem;
  color: gray;
}
.main .contain .item .box .price{
  margin-left: 1rem;
  font-size: 0.7rem;
}
.main .contain .item .box .price span{
  color: orangered;
  font-size: 1rem;
}
.main .contain .item .box .bottom{
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.main .contain .item .box .bottom .el-button{
  margin-right: 1rem;
}
</style>