<template>
  <div class="main">
    <div class="loginbox">
      <div class="left">
        <img src="../assets/host-one.png">
        <span style="position: relative;z-index: 0;color: white;letter-spacing: 0.4rem">小程序管理系统</span>
      </div>
      <div class="right">
        <h1>Login</h1>
        <el-form @submit.prevent :model="user" style="width: 80%;" size="large">
          <el-form-item>
            <el-input prefix-icon="UserFilled" placeholder="账号/学号" v-model="user.account"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input prefix-icon="lock" placeholder="密码" type="password" v-model="user.password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-col :span="12">
              <el-input placeholder="验证码" v-model="user.yzcode"></el-input>
            </el-col>
            <el-col :span="12" style="display: flex;align-items: center;">
              <img style="margin-left:8%;height: 2.4rem;width: 90%;border: 1px solid darkgray;border-radius: 0.4rem" :src="yzimg" alt=" ">
              <span class="yzimg" @click="getkaptcha">看不清?换一张</span>
            </el-col>
          </el-form-item>
          <el-form-item>
            <button class="loginbtn" @click="login">Sign in</button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-link class="beian" href="https://beian.miit.gov.cn/" target="_blank">渝ICP备2023009781号</el-link>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue";
import {loginapi} from "@/api/loginapi";
import {ElMessage} from "element-plus";
import store from "@/store";
import router from "@/router";

let yzimg=ref('')
let user=reactive({
  account:'',
  password:'',
  uuid:'',
  yzcode:''
})
function getkaptcha(){
  loginapi({
    method:'get',
    url:'kaptcha?'+Math.random()*100000,
    data:null,
    success:(res)=>{
      if (res.state==200){
        yzimg.value=res.data.url
        user.uuid=res.data.uuid
      }else {
        ElMessage.error("验证码获取失败！")
      }
    }
  })
}

function login(){
  loginapi({
    method:'post',
    url:'login',
    data:user,
    success:(res)=>{
      if(res.state==200){
        store.commit("updateToken",res.data.token)
        router.push("/index")
        ElMessage.success(res.msg)
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}

onMounted(()=>{
    getkaptcha()
})
</script>

<style scoped>
.main{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  background: url("@/assets/host.png") no-repeat;
  background-size: cover;
}
.main .loginbox{
  background: rgba(255, 255, 255, 0.8);
  height: 60%;
  width: 60%;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 4px gray;
}
.main .loginbox .left{
  float: left;
  width: 50%;
  height: 100%;
}
.main .loginbox .right{
  float: right;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main .loginbox .left img{
  position: absolute;
  width: 30%;
  height: 60%;
  z-index: 0;
  border-radius: 0.5rem;
}
.main .loginbox .left span{
  writing-mode: vertical-lr;
  color: black;
  font-weight: bolder;
  font-size: 1.8rem;
  margin-left: 12%;
  margin-top: 20%;
  text-shadow: 1px 1px 0 orange;
}
.main .loginbox .right h1{
  text-align: center;
  height: 6rem;
  line-height: 6rem;
  font-size: 2.2rem;
}
.main .loginbox .right .loginbtn{
  background: #0089ed;
  color: white;
  border: none;
  width: 100%;
  height: 2.5rem;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 2rem;
}
.main .loginbox .right .loginbtn:hover{
  background: orangered;
}
.yzimg{
  position: absolute;
  top: 2rem;
  left: 60%;
  font-size: 0.8rem;
  color: gray;
  user-select: none;
  background: linear-gradient(to right,orangered,orangered) no-repeat left 75%;
  background-size: 100% 0;
  transition: 0.5s;
}
.yzimg:hover{
  background-size: 100% 1px;
  color: black;
  cursor: pointer;
}
.main .beian{
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  color: orangered;
  font-weight: bold;
  font-size: 1rem;
  user-select: none;
}
</style>