import { createRouter, createWebHashHistory } from 'vue-router'
import myLogin from "@/views/myLogin.vue";
import {ElMessage} from "element-plus";
import store from "@/store";
import myIndex from "@/views/myIndex.vue";
import mySort from "@/views/mySort.vue";
import myAdmin from "@/views/myAdmin.vue";
import mySelf from "@/views/mySelf.vue";
import myGoods from "@/views/myGoods.vue";
import myChat from "@/views/myChat.vue";
import myOrder from "@/views/myOrder.vue";
import myPrize from "@/views/myPrize.vue";
import mySystem from "@/views/mySystem.vue";
import myBuy from "@/views/myBuy.vue";
import myExhibits from "@/views/myExhibits.vue";
import mySystemShow from "@/views/mySystemShow.vue";

const routes = [
  {path:'/',name:'login',component:myLogin},
  {path: '/index',name: '主页',component: myIndex,
    children:[
      {path: '',name: '分类-商品',component: mySort},
      {path:'/sort',name:'分类-商品',component:mySort},
      {path: '/admin',name: '人事管理',component: myAdmin},
      {path: '/self',name: '个人信息',component: mySelf},
      {path: '/goods',name: '商品管理',component: myGoods},
      {path: '/chat',name: '用户代购',component: myChat},
      {path: '/order',name: '订单',component: myOrder},
      {path: '/system',name: '系统数据',component: mySystem},
      {path: '/prize',name: '奖品',component: myPrize},
      {path: '/buy',name: '代购',component: myBuy},
      {path: '/exhibit',name: '展品',component: myExhibits},
      {path: '/systemshow',name: '系统展示数据',component: mySystemShow}
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const whitelist=['/']
router.beforeEach((to, from, next)=>{
  if(whitelist.includes(to.path)){
    next()
  }else{
    let token=localStorage.getItem("token")
    if(token==null||token==''){
      ElMessage.error("未登录，暂无权限！")
      next('/')
    }else {
      store.dispatch("getinfo")
      if(store.state.nav.length>1){
        store.state.nav.pop()
        store.state.nav.push({
          title: to.name,
          url:to.path
        })
      }else {
        store.state.nav.push({
          title: to.name,
          url:to.path
        })
      }
      next()
    }
  }
})

export default router
