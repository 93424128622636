import $ from 'jquery';
import {ElMessage} from "element-plus";


const host="ligeio.xyz"
const host2="https://"+host+":8080/"
// eslint-disable-next-line no-unused-vars
export const loginapi=({method='post',url,data,success})=>{
    $.ajax({
        type:method,
        url:host2+url,
        data:data,
        success:success,
        error:(res)=>{
            console.log(res)
            ElMessage.error("请求失败！")
        }
    })
}