<template>
<div class="main">
  <div class="head">
    <div class="search">
      <span>商品名称</span>
      <el-input placeholder="商品名字" v-model="search.search" @change="searchgoods"></el-input>
    </div>
    <div class="search">
      <span>大分类名</span>
      <el-cascader :options="f_sort" v-model="search.f" @change="search.s='';searchgoods();getssort(search.f[0])"></el-cascader>
    </div>
    <div class="search">
      <span>小分类名</span>
      <el-cascader :options="s_sort" v-model="search.s" @change="searchgoods"></el-cascader>
    </div>
    <div class="box">
      <div class="left">
        <el-input placeholder="客户ID" v-model="select.custom"></el-input>
      </div>
      <div class="right">
        <el-button size="small" v-for="item in select.goods" :key="item.id" @click="popselect(item.id)">{{item.name+'-'+item.detail}}</el-button>
      </div>
      <div class="last">
        <el-button type="primary" @click="buy">购买</el-button>
      </div>
    </div>
  </div>
  <div class="contain">
    <div class="item" v-for="(item,index) in items" :key="index">
      <img :src="item.image">
      <div class="box">
        <p>商品名称：<span>{{item.name}}</span></p>
        <p>商品详情：<span>{{item.detail}}</span></p>
        <p>价格：<span>{{item.price}}</span></p>
        <div class="bottom">
          <el-button size="small" type="success" @click="select.goods.push(item)">加入购物车</el-button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
import {onMounted, reactive} from "vue";
import {api} from "@/api/api";
import {ElMessage} from "element-plus";
import {apis} from "@/api/apis";
import {useRoute} from "vue-router";

const items=reactive([])
const f_sort=reactive([])
const s_sort=reactive([])
const search=reactive({
  search:'',
  f:'',
  s:''
})
const route=useRoute()
const select=reactive({
  custom:route.query.custom,
  goods:[]
})
function buy(){
  apis({
    method:'post',
    url:'order/buy',
    data:JSON.stringify(select),
    success:(res)=>{
      if (res.state==200){
        ElMessage.success(res.msg)
        select.custom=''
        select.goods.splice(0)
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}
function searchgoods(){
  api({
    method:'post',
    url:'goods/search',
    data:{search:search.search,f:search.f[0],s:search.s[0]},
    success:(res)=>{
      if(res.state==200){
        ElMessage.success(res.msg)
        items.splice(0)
        if(res.data){
          res.data.forEach((i)=>{
            items.push(i)
          })
        }
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}
function getssort(x){
  api({
    method:'post',
    url:'sort/gets',
    data:{search:x},
    success:(res)=>{
      if(res.state==200){
        if(res.data){
          s_sort.splice(0)
          res.data.forEach((i)=>{
            s_sort.push({value:i.name,label:i.name})
          })
        }
      }
    }
  })
}
function getfsort(){
  api({
    method:'get',
    url:'sort/getf',
    data:null,
    success:(res)=>{
      if(res.state==200){
        if(res.data){
          f_sort.splice(0)
          res.data.forEach((i)=>{
            f_sort.push({label:i.name,value:i.name})
          })
        }
      }else {
        ElMessage.error("获取大分类失败！")
      }
    }
  })
}
onMounted(()=>{
  getfsort()
  getssort(null)
})
function popselect(x){
  for (let i = 0; i < select.goods.length; i++) {
    if (select.goods[i].id==x){
      select.goods.splice(i,1)
      return
    }
  }
}
</script>

<style scoped>
.main{
  width: 100%;
  height: 100%;
}
.main .head{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px dotted darkgray;
  height: 8rem;
}
.main .head .search{
  width: 30%;
  margin-left: 2.5%;
  display: flex;
  align-items: center;
}
.main .head .search .el-input{
  width: 70%;
}
.main .head .search span{
  display: block;
  width: 22%;
}
.main .head .box{
  width: 100%;
  height: 5rem;
  display: flex;
}
.main .head .box .left{
  height: 100%;
  width: 15%;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
}
.main .head .box .right{
  width: 64%;
  height: 90%;
  overflow: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: 1px dotted deepskyblue;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 0.4rem;
}
.main .head .box .right .el-button{
  margin-left: 1rem;
  margin-top: 0.5rem;
}
.main .head .box .last{
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 1rem;
}
.main .contain{
  width: 100%;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2rem;
  height: 69.85%;
}
.main .contain .item{
  display: flex;
  flex-direction: column;
  width: 17.6%;
  margin-left: 2%;
  border-radius: 0.5rem;
  height: 17rem;
  box-shadow: 0 0 0.2rem gray;
  margin-top: 1.2rem;
}
.main .contain .item:hover{
  box-shadow: 0 0 0.4rem deepskyblue;
}
.main .contain .item img{
  width: 100%;
  height: 11rem;
  border-radius: 0.5rem 0.5rem 0 0;
}
.main .contain .item .box{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}
.main .contain .item .box p{
  font-size: 0.6rem;
  color: gray;
  text-indent: 1rem;
}
.main .contain .item .box p span{
  color: orangered;
}
.main .contain .item .box .bottom{
  width: 90%;
  margin-top: 0.5rem;
  padding-left: 6rem;
}
</style>