<template>
  <div class="main">
    <div class="left">
      <ul class="itembox">
        <li>
          <i class="iconfont icon-caidan1" style="font-size: 1.5rem;color: deepskyblue"></i>
        </li>
        <li class="li" v-for="(item,index) in f_sort" :key="index" @click="fclick(index)">
          <el-button id="x0" size="small" icon="CloseBold" type="success" @click="deletef(item.id)"></el-button>
          <span @click="f_modify=!f_modify;update_f.id=item.id;update_f.name=item.name;update_f.image=item.image">{{item.name}}</span>
        </li>
        <li class="li" @click="f_dialog=!f_dialog">
          <i class="iconfont icon-tianjia" style="font-size: 1.4rem;color: darkorange"></i>
        </li>
      </ul>
    </div>
    <div class="right">
      <div class="contain">
        <div class="item" v-for="(i,index) in s_sort" :key="index">
          <img :src="i.image" @click="s_modify=!s_modify;update_s.id=i.id;update_s.name=i.name;update_s.image=i.image">
          <span class="name">{{i.name}}</span>
          <div class="control">
            <el-button icon="CloseBold" size="small" @click="deletes(i.id)"></el-button>
          </div>
        </div>
        <div class="plus" @click="s_dialog=!s_dialog">
          <i class="iconfont icon-tianjia"></i>
        </div>
      </div>
    </div>
  </div>
  <el-dialog v-model="f_dialog" title="添加大分类">
    <el-form>
      <el-form-item label="大分类名称">
        <el-input placeholder="请输入..." v-model="fdata.name"></el-input>
      </el-form-item>
      <el-form-item label="展示图片">
        <div class="txbox">
          <img id="showf1" :src="fdata.image">
          <div class="right">
            <input id="f1" type="file" style="display: none">
            <el-button type="success" @click="selectpicturef1">选择图片</el-button>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" @click="addf">添加</el-button>
      <el-button type="danger" @click="f_dialog=!f_dialog">取消</el-button>
    </template>
  </el-dialog>
  <el-dialog v-model="s_dialog" title="添加小分类">
    <el-form>
      <el-form-item label="分类名称">
        <el-input placeholder="请输入..." v-model="sdata.name"></el-input>
      </el-form-item>
      <el-form-item label="展示图片">
        <div class="txbox">
          <img id="show" src="">
          <div class="right">
            <input id="tx" type="file" style="display: none">
            <el-button type="success" @click="selectpicture">选择图片</el-button>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" @click="adds">添加</el-button>
      <el-button type="danger" @click="s_dialog=!s_dialog">取消</el-button>
    </template>
  </el-dialog>
  <el-dialog v-model="f_modify" title="修改大分类名字">
    <el-form>
      <el-form-item title="大分类名称">
        <el-input v-model="update_f.name"></el-input>
      </el-form-item>
      <el-form-item label="展示图片">
        <div class="txbox">
          <img id="showf2" :src="update_f.image">
          <div class="right">
            <input id="f2" type="file" style="display: none">
            <el-button type="success" @click="selectpicturef2">选择图片</el-button>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" @click="updatefsort">修改</el-button>
      <el-button type="danger" @click="f_modify=!f_modify">取消</el-button>
    </template>
  </el-dialog>
  <el-dialog v-model="s_modify" title="修改小分类信息">
    <el-form>
      <el-form-item label="小分类名称">
        <el-input v-model="update_s.name"></el-input>
      </el-form-item>
      <el-form-item label="展示图片">
        <div class="txbox">
          <img id="show2" :src="update_s.image">
          <div class="right">
            <input id="tx2" type="file" style="display: none">
            <el-button type="success" @click="selectpicture2">选择图片</el-button>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" @click="updatessort">修改</el-button>
      <el-button type="danger" @click="s_modify=!s_modify">取消</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue";
import {api} from "@/api/api";
import {ElMessage} from "element-plus";
import {fileapi} from "@/api/fileapi";

  const f_sort=reactive([])
  const s_sort=reactive([])
  let f_dialog=ref(false)
  let s_dialog=ref(false)
  let f_modify=ref(false)
  let s_modify=ref(false)
  const fdata=reactive({
    name:'',
    image:''
  })
  const sdata=reactive({
    name:'',
    fName:'',
    image:''
  })
  const update_f=reactive({
    id:'',
    name:'',
    image:''
  })
  const update_s=reactive({
    id:'',
    name:'',
    image:''
  })
function updatessort(){
    let file=document.getElementById("tx2").files[0]
    if (file==undefined){
      api({
        method:'post',
        url:'sort/updates',
        data:update_s,
        success:(res)=>{
          if (res.state==200){
            ElMessage.success(res.msg)
            s_sort.forEach(i=>{
              if (i.id==update_s.id){
                i.name=update_s.name
                i.image=update_s.image
                return
              }
            })
          }else {
            ElMessage.error(res.msg)
          }
        }
      })
    }else {
      let formdata=new FormData()
      formdata.append("file",file)
      fileapi({
        method:'post',
        url:'file/picture',
        data:formdata,
        success:(res)=>{
          if (res.state==200){
            update_s.image=res.data
            api({
              method:'post',
              url:'sort/updates',
              data:update_s,
              success:(res)=>{
                if (res.state==200){
                  ElMessage.success(res.msg)
                  s_sort.forEach(i=>{
                    if (i.id==update_s.id){
                      i.name=update_s.name
                      i.image=update_s.image
                      return
                    }
                  })
                }else {
                  ElMessage.error(res.msg)
                }
              }
            })
          }else {
            ElMessage.error("商品图片上传失败！")
          }
        }
      })
    }
}
  function updatefsort(){
    let file=document.getElementById('f2').files[0]
    if (file==undefined){
      api({
        method:'post',
        url:'sort/updatef',
        data:update_f,
        success:(res)=>{
          if (res.state==200){
            ElMessage.success(res.msg)
            f_sort.forEach(i=>{
              if (i.id==update_f.id){
                i.name=update_f.name
                return
              }
            })
          }else {
            ElMessage.error(res.msg)
          }
        }
      })
    }else {
      let formdata=new FormData()
      formdata.append("file",file)
      fileapi({
        method:'post',
        url:'file/picture',
        data:formdata,
        success:(res)=>{
          if (res.state==200){
            update_f.image=res.data
            api({
              method:'post',
              url:'sort/updatef',
              data:update_f,
              success:(res)=>{
                if (res.state==200){
                  ElMessage.success(res.msg)
                  f_sort.forEach(i=>{
                    if (i.id==update_f.id){
                      i.name=update_f.name
                      i.image=update_f.image
                      return
                    }
                  })
                }else {
                  ElMessage.error(res.msg)
                }
              }
            })
          }else {
            ElMessage.error("大分类图片上传失败！")
          }
        }
      })
    }
  }

  function deletef(x){
    api({
      method:'post',
      url:'sort/deletef',
      data:{id:x},
      success:(res)=>{
        if(res.state==200){
          ElMessage.success(res.msg)
          getfsort()
          s_sort.splice(0)
        }else {
          ElMessage.error(res.msg)
        }
      }
    })
  }
function deletes(x){
  api({
    method:'post',
    url:'sort/deletes',
    data:{id:x},
    success:(res)=>{
      if(res.state==200){
        ElMessage.success(res.msg)
        getssort(sdata.fName)
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}

  function fclick(x){
    let f=document.getElementsByClassName("li")
    for (let i=0;i<f.length;i++){
      if(i==x){
        f[i].style.background='lightskyblue'
        f[i].style.color="white"
      }else {
        f[i].style.background='linear-gradient(to right,darkgray,darkgray) no-repeat left bottom'
        f[i].style.backgroundSize='100% 1px'
        f[i].style.color="orangered"
      }
    }
    sdata.fName=f_sort[x].name
    getssort(sdata.fName)
  }

  function adds(){
    let file=document.getElementById("tx").files[0]
    let formdata=new FormData()
    formdata.append("file",file)
    fileapi({
      method:'post',
      url:'file/picture',
      data:formdata,
      success:(res)=>{
        if(res.state==200){
          sdata.image=res.data
          api({
            method:'post',
            url:'sort/adds',
            data:sdata,
            success:(rs)=>{
              if(rs.state==200){
                ElMessage.success(rs.msg)
                sdata.name=''
                sdata.image=''
                getssort(sdata.fName)
              }else {
                ElMessage.error(rs.msg)
              }
            }
          })
        }else {
          ElMessage.error("图片传输失败！")
        }
      }
    })
  }
  function addf(){
    let file=document.getElementById('f1').files[0]
    if (file==undefined){
      api({
        method:'post',
        url:'sort/addf',
        data:fdata,
        success:(res)=>{
          if(res.state==200){
            getfsort()
            ElMessage.success(res.msg)
            fdata.value=''
          }else {
            ElMessage.error(res.msg)
          }
        }
      })
    }else {
      let formdata=new FormData()
      formdata.append("file",file)
      fileapi({
        method:"post",
        url:'file/picture',
        data:formdata,
        success:(res)=>{
          if (res.state==200){
            fdata.image=res.data
            api({
              method:'post',
              url:'sort/addf',
              data:fdata,
              success:(res)=>{
                if(res.state==200){
                  getfsort()
                  ElMessage.success(res.msg)
                  fdata.value=''
                }else {
                  ElMessage.error(res.msg)
                }
              }
            })
          }else {
            ElMessage.error("大分类图片上传失败！")
          }
        }
      })
    }
  }
  function selectpicture(){
    let img=document.getElementById('tx')
    img.click()
    img.onchange=()=>{
      let file=img.files[0]
      let newsrc=''
      if(window.createObjectURL!=undefined){
        newsrc=window.createObjectURL(file);
      }else if(window.URL!=undefined){
        newsrc=window.URL.createObjectURL(file);
      }else if(window.webkitURL!=undefined){
        newsrc=window.webkitURL.createObjectURL(file);
      }
      document.getElementById('show').src=newsrc
    }
  }
function selectpicture2(){
  let img=document.getElementById('tx2')
  img.click()
  img.onchange=()=>{
    let file=img.files[0]
    let newsrc=''
    if(window.createObjectURL!=undefined){
      newsrc=window.createObjectURL(file);
    }else if(window.URL!=undefined){
      newsrc=window.URL.createObjectURL(file);
    }else if(window.webkitURL!=undefined){
      newsrc=window.webkitURL.createObjectURL(file);
    }
    document.getElementById('show2').src=newsrc
  }
}
function selectpicturef1(){
  let img=document.getElementById('f1')
  img.click()
  img.onchange=()=>{
    let file=img.files[0]
    let newsrc=''
    if(window.createObjectURL!=undefined){
      newsrc=window.createObjectURL(file);
    }else if(window.URL!=undefined){
      newsrc=window.URL.createObjectURL(file);
    }else if(window.webkitURL!=undefined){
      newsrc=window.webkitURL.createObjectURL(file);
    }
    document.getElementById('showf1').src=newsrc
  }
}
function selectpicturef2(){
  let img=document.getElementById('f2')
  img.click()
  img.onchange=()=>{
    let file=img.files[0]
    let newsrc=''
    if(window.createObjectURL!=undefined){
      newsrc=window.createObjectURL(file);
    }else if(window.URL!=undefined){
      newsrc=window.URL.createObjectURL(file);
    }else if(window.webkitURL!=undefined){
      newsrc=window.webkitURL.createObjectURL(file);
    }
    document.getElementById('showf2').src=newsrc
  }
}
  function getfsort(){
    api({
      method:'get',
      url:'sort/getf',
      data:null,
      success: (res)=>{
         if(res.state==200){
          let f=document.getElementsByClassName("li")
          f_sort.splice(0)
          if(res.data){
            for (let i = 0; i < res.data.length; i++) {
              f_sort.push(res.data[i])
              if(res.data[i].name==sdata.fName){
                f[i].click()
              }
            }
          }
          setTimeout(()=>{
            if(sdata.fName==''){
              f[0].click()
            }
          },100)
        }else {
          ElMessage.error("获取大分类失败！")
        }
      }
    })
  }
  function getssort(x){
    api({
      method:'post',
      url:'sort/gets',
      data:{search:x},
      success:(res)=>{
        if(res.state==200){
          s_sort.splice(0)
          if(res.data){
            res.data.forEach((i)=>{
              s_sort.push(i)
            })
          }
        }else {
          ElMessage.error(x+"的小分类获取失败！")
        }
      }
    })
  }
  onMounted(() => {
    getfsort()
  })
</script>

<style scoped>
.main{
  width: 100%;
  height: 100%;
}
.main .left{
  float: left;
  height: 100%;
  width: 10%;
}
.main .right{
  float: right;
  height: 100%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main .left .itembox{
  width: 100%;
  height: 96%;
  margin-top: 20%;
  overflow: auto;
  background: #e9e9e9;
}
.main .left .itembox li{
  height: 3rem;
  text-align: center;
  line-height: 3rem;
  color: orangered;
  font-weight: bolder;
  font-size: 0.5em;
  background: linear-gradient(to right,darkgray,darkgray) no-repeat left bottom;
  background-size: 100% 1px;
  transition: 0.25s;
}
.main .left .itembox .li:hover{
  background: lightskyblue;
  color: white;
  cursor: pointer;
}
.main .right .contain{
  width: 90%;
  height: 90%;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  padding-top: 1rem;
}
.main .right .contain .item{
  width: 22.5%;
  height: 14rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.4rem gray;
  transition: 0.5s;
  margin-top: 1rem;
}
.main .right .contain .item:hover{
  transform: scale(1.1);
}
.main .right .contain .item .name{
  display: block;
  width: 100%;
  color: gray;
  text-align: center;
  height: 2rem;
  line-height: 2rem;
}
.main .right .contain .item .control{
  display: flex;
  justify-content: right;
  margin-bottom: 0.5rem;
  padding-right: 1rem;
}
.main .right .contain .plus{
  width: 22.5%;
  height: 14rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.2rem gray;
  margin-bottom: 2rem;
  margin-left: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(64, 158, 255,0.5);
  margin-top: 1rem;
}
.plus i{
  font-size: 3rem;
  color: white;
}
.main .right .contain .plus:hover{
  background: deepskyblue;
}
.plus:hover i{
  color: orangered;
}
.main .right .contain .item+.item{
  margin-left: 2%;
}
.main .right .contain .item:first-child{
  margin-left: 2%;
}
.main .right .contain .item img{
  width: 100%;
  height: 10rem;
  border-radius: 0.5rem 0.5rem 0 0;
}
.txbox{
  width: 100%;
  display: flex;
}
.txbox #show,#show2,#showf1,#showf2{
  width: 50%;
  box-shadow: 0 0 0.4rem gray;
  border-radius: 0.5rem;
}
#x0{
  position: absolute;
  left: 10.2rem;
  height: 1.4rem;
  width: 0.6rem;
}
#x0:hover{
  background: orangered;
}
</style>