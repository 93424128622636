<template>
  <div class="main">
    <div class="head">
      <div class="search">
        <span>商品名称</span>
        <el-input placeholder="商品名字" v-model="search.search" @change="searchgoods"></el-input>
      </div>
      <div class="search">
        <span>大分类名</span>
        <el-cascader :options="f_sort" v-model="search.f" @change="search.s='';searchgoods();getssort(search.f[0])"></el-cascader>
      </div>
      <div class="search">
        <span>小分类名</span>
        <el-cascader :options="s_sort" v-model="search.s" @change="searchgoods"></el-cascader>
      </div>
      <div class="control">
        <el-tooltip effect="light" content="添加商品">
          <el-button icon="plus" type="primary" @click="adddialog=!adddialog"></el-button>
        </el-tooltip>
        <el-tooltip effect="light" content="刷新">
          <el-button icon="RefreshRight" type="success" @click="router.go(0)"></el-button>
        </el-tooltip>
      </div>
    </div>
    <div class="contain">
      <div class="item" v-for="(i,index) in items" :key="index" @click="show(i)" :style="'background: '+getRandomColor()">
        <img :src="i.image">
        <div class="detail">
          <p>商品名字：<span>{{i.name}}</span></p>
          <p>商品描述：<span>{{i.detail}}</span></p>
          <div class="foot">
            <p>价格：￥<span>{{i.price}}</span></p>
          </div>
        </div>
      </div>
      <div class="footer"></div>
    </div>
  </div>
  <el-dialog v-model="adddialog" title="添加商品" align-center center>
    <el-form>
      <el-form-item label="商品名称">
        <el-input placeholder="请输入..." v-model="addform.name"></el-input>
      </el-form-item>
      <el-form-item label="商品大分类">
        <el-cascader :options="f_sort" v-model="addform.fName" @change="getssort(addform.fName[0])"></el-cascader>
      </el-form-item>
      <el-form-item label="商品小分类">
        <el-cascader :options="s_sort" v-model="addform.sName"></el-cascader>
      </el-form-item>
      <el-form-item label="商品图片">
        <div class="ip0">
          <img id="pc" src=" ">
          <input id="addsp" type="file" style="display: none">
          <el-button type="success" @click="selectpicture">选择图片</el-button>
        </div>
      </el-form-item>
      <el-form-item label="详情">
        <el-input type="textarea" placeholder="请输入..." v-model="addform.detail"></el-input>
      </el-form-item>
      <el-form-item label="价格">
        <el-input-number :precision="2" v-model="addform.price"></el-input-number>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" @click="addgoods">添加</el-button>
      <el-button type="danger" @click="adddialog=!adddialog">取消</el-button>
    </template>
  </el-dialog>
  <el-dialog v-model="showdialog" title="商品信息" center align-center>
    <el-form>
      <el-form-item label="商品名称">
        <el-input placeholder="请输入..." v-model="showform.name"></el-input>
      </el-form-item>
      <el-form-item label="商品大分类">
        <el-cascader :options="f_sort" v-model="showform.fName" @change="getssort(showform.fName[0])"></el-cascader>
      </el-form-item>
      <el-form-item label="商品小分类">
        <el-cascader :options="s_sort" v-model="showform.sName"></el-cascader>
      </el-form-item>
      <el-form-item label="商品图片">
        <div class="ip0">
          <img id="pcs" :src="showform.image">
          <input id="addsps" type="file" style="display: none">
          <el-button type="success" @click="selectpictures">选择图片</el-button>
        </div>
      </el-form-item>
      <el-form-item label="详情">
        <el-input type="textarea" placeholder="请输入..." v-model="showform.detail"></el-input>
      </el-form-item>
      <el-form-item label="价格">
        <el-input-number :precision="2" v-model="showform.price"></el-input-number>
      </el-form-item>
      <el-form-item label="库存">
        <el-input-number v-model="showform.num"></el-input-number>
      </el-form-item>
      <el-form-item label="已出售">
        <el-text>{{showform.outNum}}</el-text>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" @click="modifygoods">修改信息</el-button>
      <el-button type="danger" @click="deletegoods(showform.id)">下架</el-button>
      <el-button type="warning" @click="addprize(showform.id)">设置为奖品</el-button>
      <el-button @click="addshow(showform.id)">设为展品</el-button>
    </template>
  </el-dialog>
</template>

<script setup>

import {onMounted, reactive, ref} from "vue";
import {api} from "@/api/api";
import {ElMessage} from "element-plus";
import {fileapi} from "@/api/fileapi";
import router from "@/router";
const items=reactive([])
const adddialog=ref(false)
const showdialog=ref(false)
const f_sort=reactive([])
const s_sort=reactive([])
const search=reactive({
  search:'',
  f:'',
  s:''
})
const addform=reactive({
  name:'',
  fName:[],
  sName:[],
  image:'',
  detail:'',
  price:0.0
})
const showform=reactive({
  id:'',
  name:'',
  fName:[],
  sName:[],
  image:'',
  detail:'',
  price:0,
  num:'',
  outNum:''
})
function addshow(x){
  api({
    method:'post',
    url:'system/addshow',
    data:{goods:x},
    success:(res)=>{
      if (res.state==200){
        ElMessage.success(res.msg)
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}
function searchgoods(){
  api({
    method:'post',
    url:'goods/search',
    data:{search:search.search,f:search.f[0],s:search.s[0]},
    success:(res)=>{
      if(res.state==200){
        ElMessage.success(res.msg)
        items.splice(0)
        if(res.data){
          res.data.forEach((i)=>{
            items.push(i)
          })
        }
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}
function deletegoods(x){
  api({
    method:'post',
    url:'goods/delete',
    data:{id:x},
    success:(res)=>{
      if(res.state==200){
        showdialog.value=false
        ElMessage.success(res.msg)
        items.forEach((i)=>{
          if(i.id==x){
            items.splice(items.indexOf(i),1)
          }
        })
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}

function show(x){
  showform.id=x.id
  showform.name=x.name
  showform.fName.splice(0)
  showform.sName.splice(0)
  showform.fName.push(x.fname)
  showform.sName.push(x.sname)
  showform.image=x.image
  showform.detail=x.detail
  showform.price=x.price
  showform.num=x.num
  showform.outNum=x.outNum
  showdialog.value=!showdialog.value
  getssort(x.fname)
}

function addgoods(){
  let file=document.getElementById("addsp").files[0]
  let formdata=new FormData()
  formdata.append("file",file)
  fileapi({
    method:'post',
    url:'file/picture',
    data:formdata,
    success:(res)=>{
      if(res.state==200){
        addform.image=res.data
        let d={name:addform.name,fName:addform.fName[0],sName:addform.sName[0],detail:addform.detail,price:addform.price,image:addform.image}
        api({
          method:'post',
          url:'goods/add',
          data:d,
          success:(rs)=>{
            if(rs.state==200){
              ElMessage.success(rs.msg)
              addform.name=''
              addform.image=''
              addform.detail=''
              addform.price=0.0
              document.getElementById("addsp").files=null
              document.getElementById("pc").src=''
              searchgoods()
            }else {
              ElMessage.error(rs.msg)
            }
          }
        })
      }else {
        ElMessage.error("商品图片上传失败！")
      }
    }
  })
}

function getssort(x){
  api({
    method:'post',
    url:'sort/gets',
    data:{search:x},
    success:(res)=>{
      if(res.state==200){
        if(res.data){
          s_sort.splice(0)
          res.data.forEach((i)=>{
            s_sort.push({value:i.name,label:i.name})
          })
        }
      }
    }
  })
}
function getfsort(){
  api({
    method:'get',
    url:'sort/getf',
    data:null,
    success:(res)=>{
      if(res.state==200){
        if(res.data){
          f_sort.splice(0)
          res.data.forEach((i)=>{
            f_sort.push({label:i.name,value:i.name})
          })
        }
      }else {
        ElMessage.error("获取大分类失败！")
      }
    }
  })
}
function getgoods(){
  api({
    method:'get',
    url:'goods/get',
    data:null,
    success:(res)=>{
      if(res.state==200){
        if(res.data){
          items.splice(0)
          res.data.forEach((i)=>{
            items.push(i)
          })
        }
      }else {
        ElMessage.error("获取商品失败！")
      }
    }
  })
}
onMounted(()=>{
  getfsort()
  getssort(null)
  getgoods()
})
function selectpicture(){
  let img=document.getElementById('addsp')
  img.click()
  img.onchange=()=>{
    let file=img.files[0]
    let newsrc=''
    if(window.createObjectURL!=undefined){
      newsrc=window.createObjectURL(file);
    }else if(window.URL!=undefined){
      newsrc=window.URL.createObjectURL(file);
    }else if(window.webkitURL!=undefined){
      newsrc=window.webkitURL.createObjectURL(file);
    }
    document.getElementById('pc').src=newsrc
  }
}
function selectpictures(){
  let img=document.getElementById('addsps')
  img.click()
  img.onchange=()=>{
    let file=img.files[0]
    let newsrc=''
    if(window.createObjectURL!=undefined){
      newsrc=window.createObjectURL(file);
    }else if(window.URL!=undefined){
      newsrc=window.URL.createObjectURL(file);
    }else if(window.webkitURL!=undefined){
      newsrc=window.webkitURL.createObjectURL(file);
    }
    document.getElementById('pcs').src=newsrc
  }
}
function modifygoods(){
  let file=document.getElementById("addsps").files[0]
  if(file==undefined){
    let d={id:showform.id,name:showform.name,fName: showform.fName[0],sName: showform.sName[0],image:showform.image,
    price:showform.price,detail:showform.detail,num:showform.num,outName:showform.outNum}
    api({
      method:'post',
      url:'goods/update',
      data:d,
      success:(res)=>{
        if(res.state==200){
          ElMessage.success(res.msg)
          for (let i = 0; i < items.length; i++) {
            if(items[i].id==d.id){
              items.splice(i,1,d)
            }
          }
        }else {
          ElMessage.error(res.msg)
        }
      }
    })
  }else {
    let formdata=new FormData()
    formdata.append("file",file)
    fileapi({
      method:'post',
      url:'file/picture',
      data:formdata,
      success:(rs)=>{
        if(rs.state==200){
          let d={id:showform.id,name:showform.name,fName: showform.fName[0],sName: showform.sName[0],image:rs.data,
            price:showform.price,detail:showform.detail,num:showform.num,outName:showform.outNum}
          api({
            method:'post',
            url:'goods/update',
            data:d,
            success:(res)=>{
              if(res.state==200){
                ElMessage.success(res.msg)
                for (let i = 0; i < items.length; i++) {
                  if(items[i].id==d.id){
                    items.splice(i,1,d)
                  }
                }
              }else {
                ElMessage.error(res.msg)
              }
            }
          })
        }else {
          ElMessage.error("商品图片修改失败！")
        }
      }
    })
  }
}
function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
function addprize(x){
  api({
    method:'post',
    url:'system/addprize',
    data:{goods:x},
    success:(res)=>{
      if (res.state==200){
        ElMessage.success(res.msg)
      }else {
        ElMessage.error("设置奖品失败！")
      }
    }
  })
}
</script>

<style scoped>
.main{
  width: 100%;
  height: 100%;
}
.main .head{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 2rem;
  padding-bottom: 0.9rem;
  border-bottom: 1px dotted darkgray;
}
.main .head .search{
  width: 30%;
  margin-left: 2.5%;
  display: flex;
  align-items: center;
}
.main .head .search .el-input{
  width: 70%;
}
.main .head .search span{
  display: block;
  width: 22%;
}
.main .head .control{
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: row-reverse;
}
.main .head .control .el-button:first-child{
  margin-right: 10%;
  margin-left: 4%;
}
.main .contain{
  width: 100%;
  height: 79.2%;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
}
.main .contain .footer{
  width: 100%;
  height: 3rem;
}
.main .contain .item{
  margin-left: 2.2%;
  width: 22.5%;
  box-shadow: 0 0 0.4rem gray;
  border-radius: 0.5rem;
  height: 20rem;
  margin-top: 1rem;
  transition: 0.5s;
  color: white;
}
.main .contain .item:hover{
  transform: scale(1.1);
}
.main .contain .item img{
  width: 100%;
  height: 14rem;
  border-radius: 0.5rem 0.5rem 0 0;
}
.main .contain .item .detail{
  width: 100%;
  height: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main .contain .item .detail p{
  text-shadow: 0 0 0.2rem black;
}
.main .contain .item .detail p span{
  font-size: 0.8rem;
  word-break: break-all;
}
.ip0{
  width: 100%;
  display: flex;
}
.ip0 img{
  width: 8rem;
  height: 8rem;
  margin-right: 15%;
  border-radius: 0.3rem;
  box-shadow:0 0 0.3rem gray;
}
</style>