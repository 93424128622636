<template>
<div class="main">
  <div class="head">
    <div class="search">
      <span>搜索</span>
      <el-input placeholder="搜索" v-model="search" @change="searchfun"></el-input>
    </div>
    <div class="search">
      <span>状态</span>
      <el-cascader v-model="state" @change="searchstate" :options="[{label:'请选择',value:''},
      {label:'未完成',value:'未完成'},{label:'已完成',value:'已完成'}]"></el-cascader>
    </div>
  </div>
  <div class="contain">
    <div class="item" v-for="(item,index) in purchase" :key="index">
      <img :src="item.image" @click="showinfo=!showinfo;infoimg=item.image">
      <div class="box">
        <p>客户ID：<span>{{item.custom.id}}</span></p>
        <p>客户电话：<span>{{item.phone}}</span></p>
        <p>状态：<span>{{item.state}}</span></p>
        <div class="bottom">
          <el-button size="small" type="success" v-show="item.state=='未完成'" @click="router.push({path:'/buy',query:{custom:item.custom.id}})">去购买</el-button>
          <el-button size="small" type="primary" v-show="item.state=='未完成'" @click="finishp(item.id)">完成</el-button>
          <el-button size="small" type="danger" v-show="item.state=='已完成'" @click="deletep(item.id)">删除</el-button>
        </div>
      </div>
    </div>
  </div>
</div>
  <el-dialog v-model="showinfo" :show-close="false" align-center>
    <img :src="infoimg" id="showinfo">
  </el-dialog>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue";
import {api} from "@/api/api";
import {ElMessage} from "element-plus";
import router from "@/router";

const purchase=reactive([])
const showinfo=ref(false)
const infoimg=ref('')
const socket=new WebSocket(
  "wss://ligeio.xyz:8081/chat?token="+localStorage.getItem("token")+"&type=admin")
socket.onopen=()=>{
  console.log("连接成功！")
}
socket.onmessage=(res)=>{
  let data=JSON.parse(res.data)
  if (data.code=='update'){
    router.go(0)
  }
}
socket.onerror=(event)=>{
  console.log("连接错误！")
  console.log(event)
}
socket.onclose=()=>{
  console.log("链接关闭！")
}
const search=ref('')
function searchfun(){
  api({
    method:'post',
    url:'purchase/search',
    data:{search:search.value},
    success:(res)=>{
      if (res.state==200){
        purchase.splice(0)
        res.data.forEach(i=>{
          purchase.push(i)
        })
      }else {
        ElMessage.error("搜索失败！")
      }
    }
  })
}
const state=ref('')
function searchstate(){
  api({
    method:'post',
    url:'purchase/searchbystate',
    data:{search:state.value[0]},
    success:(res)=>{
      if (res.state==200){
        purchase.splice(0)
        res.data.forEach(i=>{
          purchase.push(i)
        })
      }else {
        ElMessage.error("搜索失败！")
      }
    }
  })
}
function deletep(x){
  api({
    method:'post',
    url:'purchase/delete',
    data:{id:x},
    success:(res)=>{
      if (res.state==200){
        ElMessage.success(res.msg)
        purchase.forEach(i=>{
          if (i.id==x){
            purchase.splice(purchase.indexOf(i),1)
          }
        })
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}
function finishp(x){
  api({
    method:'post',
    url:'purchase/finish',
    data:{id:x},
    success:(res)=>{
      if (res.state==200){
        ElMessage.success(res.msg)
        purchase.forEach(i=>{
          if (i.id==x){
            i.state='已完成'
          }
        })
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}
onMounted(()=>{
  api({
    method:'post',
    url:'purchase/searchbystate',
    data:{search:"未完成"},
    success:(res)=>{
      if (res.state==200){
        purchase.splice(0)
        res.data.forEach(i=>{
          purchase.push(i)
        })
      }else {
        ElMessage.error("搜索失败！")
      }
    }
  })
})
</script>

<style scoped>
.main{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.main .head{
  display: flex;
  align-items: center;
  width: 100%;
  height: 5rem;
  box-shadow: 0 0 0.1rem gray;
}
.main .head .search{
  display: flex;
  align-items: center;
  margin-left: 3rem;
}
.main .head .search span{
  display: block;
  word-break: keep-all;
  width: 5rem;
  text-align: right;
  margin-right: 0.5rem;
}
.main .contain{
  width: 100%;
  height: 98%;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2rem;
}
.main .contain .item{
  display: flex;
  flex-direction: column;
  width: 17.6%;
  margin-left: 2%;
  border-radius: 0.5rem;
  height: 17rem;
  box-shadow: 0 0 0.2rem gray;
  margin-top: 1.2rem;
}
.main .contain .item:hover{
  box-shadow: 0 0 0.4rem deepskyblue;
}
.main .contain .item img{
  width: 100%;
  height: 11rem;
  border-radius: 0.5rem 0.5rem 0 0;
}
.main .contain .item .box{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}
.main .contain .item .box p{
  font-size: 0.6rem;
  color: gray;
  text-indent: 1rem;
}
.main .contain .item .box p span{
  color: orangered;
}
.main .contain .item .box .bottom{
  width: 90%;
  margin-top: 0.5rem;
  padding-left: 1rem;
}
#showinfo{
  width: 100%;
  height: 100%;
}
</style>