<template>
<div class="main" v-loading="loading">
  <div class="box">
    <div class="item">
      <span>邀请折扣范围设置</span>
      <el-input-number :precision="2" v-model="invite.min" @change="invitemodify"></el-input-number>
       ——
      <el-input-number :precision="2" v-model="invite.max" @change="invitemodify"></el-input-number>
    </div>
    <div class="item">
      <span>签到折扣范围设置</span>
      <el-input-number :precision="2" v-model="sign.min" @change="signmodify"></el-input-number>
       ——
      <el-input-number :precision="2" v-model="sign.max" @change="signmodify"></el-input-number>
    </div>
    <div class="item">
      <span>中奖比率设置</span>
      <el-input-number v-model="prizerate" @change="updateRate"></el-input-number>
    </div>
    <div class="item">
      <span>小程序主页图片</span>
      <img class="showimg" :src="Img"/>
      <input style="display: none" id="ig" type="file" accept="image/*">
      <el-button type="primary" @click="updateImg">修改</el-button>
    </div>
  </div>
</div>
</template>

<script setup>
import {api} from "@/api/api";
import {onMounted, reactive, ref} from "vue";
import {ElMessage} from "element-plus";
import {fileapi} from "@/api/fileapi";

const sign=reactive({
  id:'',
  min:0,
  max:0
})
const invite=reactive({
  id:'',
  min:0,
  max:0
})
const prizerate=ref("")
function getsign(){
  api({
    method:'get',
    url:'system/getsign',
    success:(res)=>{
      if (res.state==200){
        sign.id=res.data.id
        sign.min=res.data.min
        sign.max=res.data.max
      }else {
        ElMessage.error("获取折扣信息失败！")
      }
    }
  })
}
function getinvite(){
  api({
    method:'get',
    url:'system/getinvite',
    success:(res)=>{
      if (res.state==200){
        invite.id=res.data.id
        invite.min=res.data.min
        invite.max=res.data.max
      }else {
        ElMessage.error("获取折扣信息失败！")
      }
    }
  })
}
function getRate(){
  api({
    method:'get',
    url:'system/getrate',
    success:(res)=>{
      if (res.state==200){
        prizerate.value=res.data
      }else {
        ElMessage.error("获取中奖率失败！")
      }
    }
  })
}
const Img=ref("")
function getImg(){
  api({
    method:'get',
    url:'system/getimg',
    success:(res)=>{
      if (res.state==200){
        Img.value=res.data
      }else {
        ElMessage.error("获取中奖率失败！")
      }
    }
  })
}
const loading=ref(false)
function updateImg(){
  loading.value=true
  let ig=document.getElementById("ig")
  ig.click()
  ig.onchange = ()=> {
    let file=ig.files[0]
    let formdata=new FormData()
    formdata.append("file",file)
    fileapi({
      method:'post',
      url:'file/picture',
      data:formdata,
      success:(res)=>{
        if (res.state==200){
          api({
            method:'post',
            url:'system/updateimg',
            data:{data:res.data},
            success:(rs)=>{
              loading.value=false
              if (rs.state==200){
                ElMessage.success("修改成功！")
                Img.value=res.data
              }else {
                ElMessage.error(rs.msg)
              }
            }
          })
        }else {
          loading.value=false
          ElMessage.error(res.msg)
        }
      }
    })
  }
  loading.value=false
}
onMounted(()=>{
  getsign()
  getinvite()
  getRate()
  getImg()
})
function signmodify(){
  api({
    method:'post',
    url:'system/modifysign',
    data:sign,
    success:(res)=>{
      if (res.state==200){
        ElMessage.success(res.msg)
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}
function invitemodify(){
  api({
    method:'post',
    url:'system/modifyinvite',
    data:invite,
    success:(res)=>{
      if (res.state==200){
        ElMessage.success(res.msg)
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}
function updateRate(){
  api({
    method:'post',
    url:'system/updaterate',
    data:{data:prizerate.value},
    success:(res)=>{
      if (res.state==200){
        ElMessage.success(res.msg)
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}
</script>

<style scoped>
.main{
  width: 100%;
  display: flex;
}
.main .box{
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  margin-left: 20%;
}
.main .box .item{
  margin-top:2rem ;
  display: flex;
  align-items: center;
}
.main .box .item span{
  width: 9rem;
  display: block;
}
.main .box .item .showimg{
  width: 9rem;
  height: 9rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.4rem gray;
  margin-right: 5rem;
}
</style>