<template>
  <div class="box">
    <div class="left">
      <el-form class="infobox" label-width="60">
        <el-form-item label="类型">
          <el-text>{{store.state.user.type}}</el-text>
        </el-form-item>
        <el-form-item label="名字">
          <el-input v-model="store.state.user.name"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio-group v-model="store.state.user.sex">
            <el-radio label="男">男</el-radio>
            <el-radio label="女">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="store.state.user.phone"></el-input>
        </el-form-item>
        <el-form-item label="头像">
          <img id="show" :src="store.state.user.image">
          <input id="tx" type="file" style="display: none">
          <el-button @click="selecttx">选择头像</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="margin: 0 auto" @click="modify">信息修改</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="right">
      <h2 style="color: gray;position: absolute;top: 10rem">密码修改</h2>
      <el-form :model="mm" class="mm">
        <el-form-item label="旧密码">
          <el-input type="password" v-model="mm.oldpassword" placeholder="旧密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input v-model="mm.password" placeholder="新密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" style="margin: 0 auto" @click="mmmodify">密码修改</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup>

import store from "../store";
import {reactive} from "vue";
import {api} from "@/api/api";
import {ElMessage} from "element-plus";
import {fileapi} from "@/api/fileapi";
import router from "@/router";
const mm=reactive({
  id:'',
  oldpassword:'',
  password:''
})
function selecttx(){
  let img=document.getElementById('tx')
  img.click()
  img.onchange=()=>{
    let file=img.files[0]
    let newsrc=''
    if(window.createObjectURL!=undefined){
      newsrc=window.createObjectURL(file);
    }else if(window.URL!=undefined){
      newsrc=window.URL.createObjectURL(file);
    }else if(window.webkitURL!=undefined){
      newsrc=window.webkitURL.createObjectURL(file);
    }
    document.getElementById("show").src=newsrc;
  }
}
function modify(){
  let formdata=new FormData()
  let file=document.getElementById("tx").files[0]
  if(file==undefined){
    api({
      method:'post',
      url:'user/update',
      data:{id:store.state.user.id,name:store.state.user.name,phone:store.state.user.phone,sex:store.state.user.sex,
        image:store.state.user.image
      },
      success:(res)=>{
        if(res.state==200){
          ElMessage.success(res.msg)
        }else {
          ElMessage.error(res.msg)
          router.go(0)
        }
      }
    })
  }else {
    formdata.append("file",file)
    fileapi({
      method:'post',
      url:'file/picture',
      data:formdata,
      success:(res)=>{
        if(res.state==200){
          api({
            method:'post',
            url:'user/update',
            data:{id:store.state.user.id,name:store.state.user.name,phone:store.state.user.phone,sex:store.state.user.sex,
              image:res.data
            },
            success:(rs)=>{
              if(rs.state==200){
                ElMessage.success(rs.msg)
                router.go(0)
              }else {
                ElMessage.error(rs.msg)
                router.go(0)
              }
            }
          })
        }else {
          ElMessage.error("更新头像失败！")
        }
      }
    })
  }
}
function mmmodify(){
  mm.id=store.state.user.id
  api({
    method:'post',
    url:'user/modifypassword',
    data:mm,
    success:(res)=>{
      if(res.state==200){
        ElMessage.success(res.msg)
        mm.oldpassword=''
        mm.password=''
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}
</script>

<style scoped>
.box{
  width: 100%;
  height: 100%;
  background: linear-gradient(to right,pink,greenyellow,deepskyblue);
}
.box .left{
  float: left;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: 1px dotted deepskyblue;
}
.box .right{
  float: right;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.infobox{
  width: 80%;
  margin-top: 12%;
}
.infobox img{
  height: 10rem;
  border-radius: 50%;
  margin-right: 8%;
}
.right .mm{
  width: 60%;
  margin-top: 30%;
}
</style>