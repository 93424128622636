<template>
  <div class="main">
    <el-menu :collapse="collapse" style="height: 100%;float: left;transition: 0.5s;overflow: auto;">
      <i class="iconfont icon-schoollogo" style="color: #409eff;font-size: 4.5rem;width: 100%;display: flex;
      justify-content: center;margin-top: 10%;margin-bottom: 10%"></i>
      <h3 style="text-align: center;margin-bottom: 5%;color: orangered">后台管理</h3>
      <el-sub-menu v-for="(item,index) in menu" :key="index" :index="(index+1).toString()">
        <template #title>
          <el-icon>
            <i class="iconfont" :class="item.icon" style="color: #41b883;font-size: 1.2rem"></i>
          </el-icon>
          <span>{{item.title}}</span>
        </template>
        <el-menu-item v-for="(i,num) in item.child" :key="num" @click="router.push(i.url)"
                      :index="(index+1).toString()+'-'+(num+1).toString()">
          <el-icon>
            <i class="iconfont" :class="i.icon" style="font-size: 1.2rem"></i>
          </el-icon>
          <template #title>{{i.title}}</template>
        </el-menu-item>
      </el-sub-menu>
      <el-menu-item index="9" @click="layout">
        <el-icon>
          <i class="iconfont icon-tuichu"></i>
        </el-icon>
        <template #title>退出</template>
      </el-menu-item>
    </el-menu>
    <div class="right">
      <div class="head">
        <i id="navhead" class="iconfont" :class="collapseicon" @click="changemenuicon"></i>
        <el-breadcrumb separator="/" style="margin-left: 8%;max-width: 60%">
          <el-breadcrumb-item v-for="(i,index) in store.state.nav" :key="index" :to="i.path">
            {{i.title}}
          </el-breadcrumb-item>
        </el-breadcrumb>
        <el-dropdown style="position: absolute;left: 80%">
        <span class="el-dropdown-link" style="font-weight: bolder;display: flex;align-items: center;cursor: pointer">
          <el-avatar class="el-icon--left" :src="store.state.user.image" :size="50" shape="circle"></el-avatar>
           {{store.state.user.name}}
        </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item disabled>用户角色: {{store.state.user.type}}</el-dropdown-item>
              <el-dropdown-item @click="router.push('/self')">修改信息</el-dropdown-item>
              <el-dropdown-item @click="layout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="contain">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref} from "vue";
import router from "@/router";
import store from "@/store";
import {ElMessage} from "element-plus";

const collapse=ref(false)
let collapseicon=ref('icon-caidan')
let menu=reactive([
  {title:'分类-商品',icon:'icon-fenlei',
    child:[
      {title:'分类管理',icon:'icon-fenlei4-copy',url:'/sort'},
      {title: '商品管理',icon: 'icon-goods',url: '/goods'}
    ]
  },
  {
    title: '代购管理',icon: 'icon-goods',
    child: [
      {title: '用户代购',icon:'icon-goods',url: '/chat'},
      {title: '订单购买',icon: 'icon-dingdan',url: '/buy'}
    ]
  },
  {
    title: '订单管理',icon: 'icon-guanli',
    child: [
      {title: '订单管理',icon: 'icon-dingdan',url: '/order'}
    ]
  },
  {
    title: '系统管理',icon: 'icon-shezhi',
    child: [
      {title: '公告设置',icon: 'icon-tongzhi1',url: '/systemshow'},
      {title: '展品管理',icon: 'icon-shangjia',url: '/exhibit'},
      {title: '奖励管理',icon: 'icon-jifen',url:'/system'},
      {title: '抽奖管理',icon:'icon-a-qingzhu',url:'/prize'}
    ]
  },
  {title: '管理员管理',icon:'icon-guanliyuan',
    child:[
      {title: '管理员管理',icon: 'icon-yonghu',url: '/admin'}
    ]
  },
  {title: '个人中心',icon:'icon-yonghu1',
    child: [
      {title: '个人信息',icon: 'icon-xinxi',url: '/self'}
    ]
  }
])
function changemenuicon(){
  collapse.value=!collapse.value
  if(collapse.value){
    collapseicon.value='icon-caidan2'
  }else {
    collapseicon.value='icon-caidan'
  }
}
function layout(){
  ElMessage.info("退出系统")
  router.push('/')
  localStorage.removeItem("token")
}
</script>

<style scoped>
.main{
  width: 100%;
  height: 100%;
}
.main .el-menu::-webkit-scrollbar{
  display: none;
}
.right{
  height: 100%;
  transition: 0.5s;
}
.right .head{
  height: 4rem;
  display: flex;
  align-items: center;
  transition: 0.5s;
  box-shadow: 0 0 0.2rem gray;
}
.right .head .iconfont{
  font-size: 1.5rem;
  margin-left: 2%;
}
.right .contain{
  height: 90.5%;
  overflow: auto;
}
#navhead:hover{
  color: green;
  transform: scale(1.2);
}
</style>