import {api} from "@/api/api";
import store from "@/store/index";
import {ElMessage} from "element-plus";

export default ({
    state: {
        id:'',
        account:'',
        name:'',
        age:0,
        sex:'',
        phone:'',
        type:'',
        image:'',
        token:''
    },
    getters: {
    },
    mutations: {
        updateInfo(state,data){
            state.id=data.id
            state.account=data.account
            state.name=data.name
            state.sex=data.sex
            state.phone=data.phone
            state.type=data.type
            state.image=data.image
        },
        updateToken(state,data){
            state.token=data
            localStorage.setItem("token",data)
        }
    },
    actions: {
        getinfo(){
            api({
                method:'get',
                url:'getinfo',
                data:null,
                success:(res)=>{
                    if(res.state==200){
                        store.state.user.token=localStorage.getItem("token")
                        store.commit("updateInfo",res.data)
                    }else {
                        ElMessage.error("获取信息失败！")
                    }
                }
            })
        }
    },
    modules: {
    }
})