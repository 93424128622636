<template>
<div class="main">
  <div class="head">
    <div class="search">
      <span>搜索</span>
      <el-input placeholder="搜索(用户信息)..." v-model="find" @change="searchorder"></el-input>
    </div>
    <div class="search">
      <span>状态</span>
      <el-cascader :options="option" v-model="state" @change="searchState"></el-cascader>
    </div>
  </div>
  <div class="contain">
    <div class="item" v-for="(item,index) in order" :key="index">
      <img :src="item.goods.image" alt="">
      <div class="box">
        <p class="custom">客户ID：<span>{{item.customId}}</span></p>
        <p class="custom">客户姓名：<span>{{item.customName}}</span></p>
        <p class="name">{{item.goods.name}}</p>
        <p class="detail">{{ item.goods.detail }}</p>
        <div class="pricenum">
          <p>价格：￥ <span>{{item.price}}</span></p>
          <p>x <span>{{item.num}}件</span></p>
        </div>
        <p class="state">{{item.state}}</p>
        <div class="bottom">
          <el-button size="small" type="success" @click="doPost(item.id)" v-show="item.state=='待发货'">发货</el-button>
          <el-button size="small" type="warning" @click="quxiao(item.id)">退单</el-button>
          <el-button size="small" type="danger" @click="deleteorder(item.id)" v-show="item.state=='已完成'">删除</el-button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue";
import {ElMessage} from "element-plus";
import {api} from "@/api/api";

const option=reactive([
  {label:'请选择',value:''},{label: '待发货',value: '待发货'},{label: '待收货',value: '待收货'},{label: '已完成',value: '已完成'}
])
const order=reactive([])
function getState(x){
  api({
    method:'post',
    url:'order/getstate',
    data:{state:x},
    success:(res)=>{
      if (res.state===200){
        order.splice(0)
        res.data.forEach(i=>{
          order.push(i)
        })
      }else {
        ElMessage.error("获取订单失败！")
      }
    }
  })
}
onMounted(()=>{
  getState("待发货")
})
const state=ref('')
function searchState(){
  getState(state.value[0])
}
function doPost(x){
  api({
    method:'post',
    url:'order/dopost',
    data:{id:x},
    success:(res)=>{
      if (res.state==200){
        ElMessage.success(res.msg)
        order.forEach(i=>{
          i.state='待收货'
        })
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}
const find=ref('')
function searchorder(){
  api({
    method:'post',
    url:'order/search',
    data:{search:find.value},
    success:(res)=>{
      if (res.state==200){
        order.splice(0)
        res.data.forEach(i=>{
          order.push(i)
        })
      }else {
        ElMessage.error("搜索订单失败！")
      }
    }
  })
}
function deleteorder(x){
  api({
    method:'post',
    url:'order/delete',
    data:{id:x},
    success:(res)=>{
      if (res.state==200){
        ElMessage.success(res.msg)
        for (let i = 0; i < order.length; i++) {
          if (order[i].id==x){
            order.splice(i,1)
          }
        }
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}
function quxiao(x){
  api({
    method:'post',
    url:'order/quxiao',
    data:{id:x},
    success:(res)=>{
      if (res.state==200){
        ElMessage.success(res.msg)
        for (let i = 0; i < order.length; i++) {
          if (order[i].id==x){
            order.splice(i,1)
          }
        }
      }else {
        ElMessage.error(res.msg)
      }
    }
  })
}
</script>

<style scoped>
.main{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.main .head{
  display: flex;
  align-items: center;
  width: 100%;
  height: 5rem;
  box-shadow: 0 0 0.1rem gray;
}
.main .head .search{
  display: flex;
  align-items: center;
  margin-left: 3rem;
}
.main .head .search span{
  display: block;
  word-break: keep-all;
  width: 5rem;
  text-align: right;
  margin-right: 0.5rem;
}
.main .contain{
  width: 100%;
  height: 96%;
  padding-top: 0.5rem;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2rem;
}
.main .contain .item{
  width: 20%;
  height: 19.5rem;
  box-shadow: 0 0 0.2rem gray;
  border-radius: 0.5rem;
  margin-left: 2.5%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}
.main .contain .item img{
  width: 100%;
  height: 10rem;
  border-radius: 0.5rem 0.5rem 0 0;
}
.main .contain .item .box{
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
}
.main .contain .item:hover{
  box-shadow: 0 0 0.6rem gray;
}
.main .contain .item .box .custom{
  text-indent: 1.5rem;
  font-size: 0.8rem;
  color: gray;
}
.main .contain .item .box .custom span{
  color: black;
  font-size: 0.8rem;
}
.main .contain .item .box .name{
  text-indent: 1.5rem;
  font-size: 0.8rem;
  color: orangered;
}
.main .contain .item .box .detail{
  font-size: 0.6rem;
  color: gray;
  text-indent: 1.5rem;
}
.main .contain .item .box .pricenum{
  display: flex;
  justify-content: space-between;
}
.main .contain .item .box .pricenum p:first-child{
  margin-left: 1.5rem;
}
.main .contain .item .box .pricenum p:last-child{
  margin-right: 1rem;
}
.main .contain .item .box .pricenum p{
  font-size: 0.5rem;
  color: gray;
}
.main .contain .item .box .pricenum p span{
  color: orangered;
  font-size: 1rem;
}
.main .contain .item .box .state{
  color: deepskyblue;
  margin-left: 1.5rem;
  font-size: 0.6rem;
}
.main .contain .item .box .bottom{
  padding-left: 1.5rem;
  display: flex;
  margin-top: 0.5rem;
}
</style>